

import {Component, Prop, VModel, Vue} from "vue-property-decorator";
import {Breed} from "@/_services/models/Breed";
import { marked } from "marked";

@Component
export default class BreedDetailsDialog extends Vue {

  @VModel({ type: Boolean, default: false }) open!: boolean;

  @Prop({ type: Object }) breed!: Breed;

  get renderMarkdown() {
    return marked(this.breed.description);
  }
}
