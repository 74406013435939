

import {Component, Prop, VModel, Vue} from "vue-property-decorator";
import {Feedback} from "@/_services/models/Feedback";

@Component
export default class FeedbackDetail extends Vue {

  @VModel({ type: Boolean, default: false }) open!: boolean;
  @Prop({ type: Object }) feedback!: Feedback;

}
