
import {Component, Vue, Watch} from "vue-property-decorator";
import Navbar from "@/components/Navbar.vue";
import {AdminAuthenticatedRouteNames, AdminRouteNames, RouteNames} from "@/router";
import SidebarAdmin from "@/components/SidebarAdmin.vue";
import {namespace} from "vuex-class";

const authModule = namespace('AuthModule');

@Component({
  components: {SidebarAdmin, Navbar}
})
export default class App extends Vue {

  @authModule.Getter
  public isAuthenticated!: boolean;

  hidden = true;


  get isAdminRoute(): boolean {
    return AdminAuthenticatedRouteNames.includes(this.$route.name as RouteNames) || window.location.href.includes('/admin');
  }

}

