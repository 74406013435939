

import {Component, Vue} from "vue-property-decorator";
import {Label} from "@/_services/models/Label";
import {LabelService} from "@/_services/label.service";
import Textbox from "@/components/Textbox.vue";
@Component({
  components: {Textbox}
})
export default class AdminLabelView extends Vue {

  labels: Label[] = [];
  editActive = false;
  edit = '';
  labelTmp = '';
  editProp: Label = new Label();
  search = '';

  async mounted(): Promise<void> {
    this.labels = await LabelService.retrieveAllLabel();
  }

  async saveLabel(): Promise<void> {
    await LabelService.editLabelByKey(this.editProp.key, this.editProp.label);
    this.editActive = false;
  }

  onClose(): void {
    this.editProp.label = this.labelTmp;
  }

}
