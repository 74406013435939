
import {Vue, Component, Watch} from 'vue-property-decorator';
import CreateFeedback from "@/components/CreateFeedback.vue";
import FeedbackCard from "@/components/FeedbackCard.vue";
import {Cat} from "@/_services/models/Cat";
import {CatService} from "@/_services/cat.service";
import {marked} from "marked";
import dayjs from "dayjs";
import MobileMixin from "@/mixins/MobileMixin";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";

@Component({
  components: {FeedbackCard, CreateFeedback, VuePdfEmbed}
})
export default class FemaleView extends MobileMixin {

  cat: Cat = new Cat();
  activeBreedDetail = false;
  pdfDialog = false;
  srcPdf = '';
  titlePdfDialog = '';

  get renderMarkdown() {
    return marked(this.cat.breed.description);
  }

  get getBornFormatted(): string {
    const age = dayjs().diff(dayjs(this.cat.born), 'year');
    return `${age} ${age > 1 ? 'ans' : 'an'}`;
  }

  async mounted(): Promise<void> {
    await this.retrieveCat();
  }

  openPdfDialog(srcPdf: string, title: string): void {
    this.titlePdfDialog = title;
    this.srcPdf = srcPdf;
    this.pdfDialog = true;
  }

  @Watch('$route.params.id')
  async retrieveCat(): Promise<void> {
    this.cat = await CatService.retrieveById(this.$route.params.id);
  }

}
