

import {Component, Vue} from "vue-property-decorator";
import {Feedback} from "@/_services/models/Feedback";
import {FeedbacksService} from "@/_services/feedbacks.service";
import FeedbackCard from "@/components/FeedbackCard.vue";
import EmptyState from "@/components/EmptyState.vue";
@Component({
  components: {EmptyState, FeedbackCard}
})
export default class AdminFeedbackView extends Vue {

  feedbacksPublished: Feedback[] = [];
  feedbacksNotPublished: Feedback[] = [];
  allFeedbacks: Feedback[] = [];

  async mounted(): Promise<void> {
    this.feedbacksPublished = await FeedbacksService.retrieveAllFeedbacksPublished();
    this.feedbacksNotPublished = await FeedbacksService.retrieveAllFeedbacksNotPublished();
    this.allFeedbacks = await FeedbacksService.retrieveAllFeedbacks();
  }

  async togglePublished(published: boolean, feedback: Feedback): Promise<void> {
    if (feedback.id) {
      const response: Feedback = await FeedbacksService.updatePublishedFeedback(feedback.id);
      if (response.published) {
        this.feedbacksNotPublished = this.feedbacksNotPublished.filter((f: Feedback) => f.id !== feedback.id);
        this.feedbacksPublished.push(response);
      } else {
        this.feedbacksPublished = this.feedbacksPublished.filter((f: Feedback) => f.id !== feedback.id);
        this.feedbacksNotPublished.unshift(response);
      }
    }
  }

}
