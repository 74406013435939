

import {Component, Vue} from "vue-property-decorator";
import {Cat, CatCreatedRequest} from "@/_services/models/Cat";
import FormCatDialog from "@/components/FormCatDialog.vue";
import {CatService} from "@/_services/cat.service";
import EmptyState from "@/components/EmptyState.vue";
import MessageDialog from "@/components/MessageDialog.vue";
import {Breed} from "@/_services/models/Breed";
import {BreedService} from "@/_services/breed.service";
@Component({
  components: {MessageDialog, EmptyState, FormCatDialog}
})
export default class AdminCatView extends Vue {

  females: Cat[] = [];
  openCreateCat = false;
  openDeleteCat = false;
  catToBeEdited: CatCreatedRequest = new CatCreatedRequest();
  catToBeDeleted: CatCreatedRequest = new CatCreatedRequest();
  search = '';

  async mounted(): Promise<void> {
    this.females = await CatService.retrieveAllCat();
  }

  async deleteCat(): Promise<void> {
    if (!this.catToBeDeleted.id) {
      return;
    }
    await CatService.deleteById(this.catToBeDeleted.id);
    this.females = this.females.filter(b => b.id !== this.catToBeDeleted.id);
  }

  onCreateFemale(): void {
    this.catToBeEdited = new CatCreatedRequest();
    this.openCreateCat = true;
  }

  onSavedCat(cat: Cat): void {
    this.females.push(cat);
    this.females = this.females.sort((a, b) => a.name.localeCompare(b.name));
  }

  onUpdatedBreed(cat: Cat | undefined): void {
    if (cat) {
      const index = this.females.findIndex(b => b.id === cat.id);
      this.females.splice(index, 1, cat);
      this.females = this.females.sort((a, b) => a.name.localeCompare(b.name));
    }
  }
}
