import {Breed} from "@/_services/models/Breed";

export enum CatStatus {
    RETIRE = 'RETIRE',
    NOTHING = 'NOTHING',
    LITTER = 'LITTER'
}
export class Cat {
    id?: string;
    name = '';
    description = '';
    photo = '';
    loofUrl = '';
    testsUrl = '';
    breed: Breed = new Breed();
    coat = '';
    born = '';
    status: CatStatus = CatStatus.NOTHING;
    loof = false;
}

export class CatCreatedRequest {
    id?: string;
    name = '';
    description = '';
    photo = '';
    loofUrl = '';
    testsUrl = '';
    idBreed = '';
    coat = '';
    born = '';
    status: CatStatus = CatStatus.NOTHING;
    loof = false;
}
