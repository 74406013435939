

import {Component, Prop, Vue} from "vue-property-decorator";
import {Feedback} from "@/_services/models/Feedback";
import FeedbackDetail from "@/components/FeedbackDetail.vue";
@Component({
  components: {FeedbackDetail}
})
export default class FeedbackCard extends Vue {

  @Prop({ type: Object }) feedback!: Feedback;

  feedbackDetail = false;

  openFeedbackDetail(): void {
    this.feedbackDetail = true;
  }

}
