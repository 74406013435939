

import {Component, Vue} from "vue-property-decorator";
import MobileMixin from "@/mixins/MobileMixin";
import {Cat} from "@/_services/models/Cat";
import {CatService} from "@/_services/cat.service";

@Component
export default class Navbar extends MobileMixin {

  active = 'home';
  females: Cat[] = [];

  async mounted(): Promise<void> {
    this.females = await CatService.retrieveAllCat();
  }

}
