

import {Component, Vue} from "vue-property-decorator";
import QuoteCard from "@/components/QuoteCard.vue";
import {Cat} from "@/_services/models/Cat";
import CardCat from "@/components/CardCat.vue";
import MobileMixin from "@/mixins/MobileMixin";
import {Label} from "@/_services/models/Label";
import {LabelService} from "@/_services/label.service";

@Component({
  components: {CardCat, QuoteCard}
})
export default class HomeView extends MobileMixin {

  labels: Label[] = [];

  async mounted(): Promise<void> {
    this.labels = await LabelService.retrieveAllByPage('HOME');
  }

  gl(key: string): string {
    return this.labels?.filter(label => label.key === key).pop()?.label ?? '';
  }

}
