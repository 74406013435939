

import {Component, Emit, Prop, Ref, VModel, Vue, Watch} from "vue-property-decorator";

@Component
export default class ImagePicker extends Vue {

  @Ref('filepicker') filepicker!: HTMLInputElement;

  @Prop({ type: String, default: '' }) label!: string;
  @Prop({ type: Boolean, default: false }) fileMode!: boolean;

  file: File | null = null;
  @Prop({ type: String, default: '' }) preview!: string;
  previewLocal = '';

  mounted(): void {
    this.filepicker.addEventListener('change', (event: Event) => {
      const target = event.target as HTMLInputElement;
      this.changeFile((target.files as FileList)[0]);
    });
    this.previewLocal = this.preview;
  }

  @Emit()
  changeFile(file: File): File {
    this.file = file;
    this.previewLocal = URL.createObjectURL(file);
    console.log(file);
    return file;
  }

  @Watch('preview')
  onWatchPreview(): void {
    this.previewLocal = this.preview;
  }

  onClick(): void {
    this.filepicker.click();
  }


}
