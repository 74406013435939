import axios from "axios";
import {ApiConfig} from "@/_services/ApiConfig";
import axiosInstance from "@/_services/axiosInstance";

export class S3Service {

    static async uploadImage(image: FormData): Promise<string> {
        const response = await axiosInstance.post<string>(`${ApiConfig.API_URL}/s3/image`, image, {
            withCredentials: true,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data
    }

}
