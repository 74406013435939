import Vue from 'vue';
import App from './App.vue';

import '@/assets/styles/general.scss';

import vuesax from 'vuesax';
import 'boxicons/css/boxicons.min.css'

import 'vuesax/dist/vuesax.css' //Vuesax styles
import '@/plugins/carousel3d';

import router from './router';
import store from './stores';

Vue.config.warnHandler = (err, vm, info) => {
  console.warn(err);
}

Vue.config.errorHandler = (err, vm, info) => {
  console.error('error', err);
};

Vue.use(vuesax, {
  // options here
});


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
