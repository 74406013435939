import {Breed} from "@/_services/models/Breed";
import axiosInstance from "@/_services/axiosInstance";
import {ApiConfig} from "@/_services/ApiConfig";

export class BreedService {

    static async createBreed(breed: Breed): Promise<Breed> {
        const response = await axiosInstance.post<Breed>(`${ApiConfig.API_URL}/breed`, breed, { withCredentials: true });
        return response.data;
    }

    static async retrieveAllBreed(): Promise<Breed[]> {
        const response = await axiosInstance.get<Breed[]>(`${ApiConfig.API_URL}/breed`, { withCredentials: true });
        return response.data;
    }

    static async updateById(id: string, breed: Breed): Promise<Breed> {
        const response = await axiosInstance.put<Breed>(`${ApiConfig.API_URL}/breed/${id}`, { ...breed }, { withCredentials: true });
        return response.data;
    }

    static async deleteById(id: string): Promise<void> {
        await axiosInstance.delete(`${ApiConfig.API_URL}/breed/${id}`, { withCredentials: true });
    }

}
