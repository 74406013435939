

import {Component, Vue} from "vue-property-decorator";
import {Breed} from "@/_services/models/Breed";
import EmptyState from "@/components/EmptyState.vue";
import Textbox from "@/components/Textbox.vue";
import {BreedService} from "@/_services/breed.service";
import FormBreedDialog from "@/components/FormBreedDialog.vue";
import MessageDialog from "@/components/MessageDialog.vue";
import BreedDetailsDialog from "@/components/BreedDetailsDialog.vue";
@Component({
  components: {BreedDetailsDialog, MessageDialog, FormBreedDialog, Textbox, EmptyState}
})
export default class AdminBreedView extends Vue {

  breeds: Breed[] = [];
  breedToBeUpdated: Breed = new Breed();
  breedToBeDelete: Breed = new Breed();
  breedToPreview: Breed = new Breed();
  openDeleteBreed = false;
  openPreviewBreed = false;
  openFormBreed = false;
  search = '';

  async mounted(): Promise<void> {
    this.breeds = await BreedService.retrieveAllBreed();
  }

  onCreateBreed(): void {
    this.breedToBeUpdated = new Breed();
    this.openFormBreed = true;
  }

  onSavedBreed(breed: Breed): void {
    this.breeds.push(breed);
    this.breeds = this.breeds.sort((a, b) => a.name.localeCompare(b.name));
  }

  async deleteBreed(): Promise<void> {
    if (!this.breedToBeDelete.id) {
      return;
    }
    await BreedService.deleteById(this.breedToBeDelete.id);
    this.breeds = this.breeds.filter(b => b.id !== this.breedToBeDelete.id);
  }

  onUpdatedBreed(breed: Breed | undefined): void {
    if (breed) {
      const index = this.breeds.findIndex(b => b.id === breed.id);
      this.breeds.splice(index, 1, breed);
      this.breeds = this.breeds.sort((a, b) => a.name.localeCompare(b.name));
    }
  }

}
