import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {User} from "@/_services/models/User";
import {AuthService} from "@/_services/auth.service";

@Module({ namespaced: true })
class AuthModule extends VuexModule {

    public currentUser: User | null = null;

    @Mutation
    public setCurrentUser(user: User | null) {
        this.currentUser = user;
    }

    @Action
    public async checkAuth(): Promise<void> {
        const user = await AuthService.checkAuth();
        console.log(user);
        this.context.commit('setCurrentUser', user);
    }

    @Action
    public async logout(): Promise<void> {
        await AuthService.logout();
        this.context.commit('setCurrentUser', null);
    }

    @Action
    public async login(payload: {username: string, password: string}): Promise<void> {
        const user = await AuthService.login(payload.username, payload.password);
        this.context.commit('setCurrentUser', user);
    }

    get getCurrentUser(): User | null {
        return this.currentUser;
    }

    get isAuthenticated(): boolean {
        return !!this.currentUser;
    }

}
export default AuthModule;
