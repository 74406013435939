
import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class QuoteCard extends Vue {

  @Prop({ type: String, default: '' }) text!: string;
  @Prop({ type: String, default: '' }) author!: string;

}
