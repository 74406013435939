
import {Component, Vue} from "vue-property-decorator";
import {namespace} from "vuex-class";
import {RouteNames} from "@/router";

const authModule = namespace('AuthModule');

@Component
export default class AdminLoginView extends Vue {

  @authModule.Action
  public login!: (payload: { username: string, password: string }) => Promise<void>;

  username = '';
  password = '';

  async loginAdmin(): Promise<void> {
    await this.login({username: this.username, password: this.password});
    await this.$router.push({name: RouteNames.ADMIN_HOME});
  }

}
