

import {Component, Emit, Prop, VModel, Vue} from "vue-property-decorator";

@Component
export default class MessageDialog extends Vue {

  @VModel({ type: Boolean, default: false }) open!: boolean;

  @Prop({ type: String, default: '' }) message!: string;
  @Prop({ type: String, default: '' }) title!: string;

  @Emit()
  confirm(): void {
    this.open = false;
  }

  @Emit()
  cancel(): void {
    this.open = false;
  }

}
