

import {Component, Vue} from "vue-property-decorator";
import {namespace} from "vuex-class";

const authModule = namespace('AuthModule');

@Component
export default class SidebarAdmin extends Vue {

  @authModule.Action
  public logout!: () => Promise<void>;

  active = 'home';

  async clickLogout(): Promise<void> {
    console.log('logout')
    await this.logout();
    await this.$router.push('/');
  }

}
