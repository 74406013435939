

import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class CardCat extends Vue {

  @Prop({ type: String, default: ''}) title!: string;
  @Prop({ type: String, default: ''}) description!: string;
  @Prop({ type: String, default: ''}) src!: string;

}
