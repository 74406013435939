import {Breed} from "@/_services/models/Breed";
import axiosInstance from "@/_services/axiosInstance";
import {ApiConfig} from "@/_services/ApiConfig";
import {Cat, CatCreatedRequest} from "@/_services/models/Cat";

export class CatService {

    static async createCat(cat: CatCreatedRequest): Promise<Cat> {
        const response = await axiosInstance.post<Cat>(`${ApiConfig.API_URL}/cat`, cat, { withCredentials: true });
        return response.data;
    }

    static async retrieveById(id: string): Promise<Cat> {
        const response = await axiosInstance.get<Cat>(`${ApiConfig.API_URL}/cat/${id}`);
        return response.data;
    }

    static async retrieveAllCat(): Promise<Cat[]> {
        const response = await axiosInstance.get<Cat[]>(`${ApiConfig.API_URL}/cat`);
        return response.data;
    }

    static async updateById(id: string, cat: CatCreatedRequest): Promise<Cat> {
        const response = await axiosInstance.put<Cat>(`${ApiConfig.API_URL}/cat/${id}`, { ...cat }, { withCredentials: true });
        return response.data;
    }

    static async deleteById(id: string): Promise<void> {
        await axiosInstance.delete(`${ApiConfig.API_URL}/cat/${id}`, { withCredentials: true });
    }

}
