

import {Component, Vue} from "vue-property-decorator";
import {User} from "@/_services/models/User";
import {namespace} from "vuex-class";

const authModule = namespace('AuthModule');

@Component
export default class AdminHomeView extends Vue {

  @authModule.Getter
  public getCurrentUser!: User | null;

}
