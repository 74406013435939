

import {Component, Vue} from "vue-property-decorator";
import {FeedbacksService} from "@/_services/feedbacks.service";
import {Feedback} from "@/_services/models/Feedback";
import MobileMixin from "@/mixins/MobileMixin";
import CreateFeedback from "@/components/CreateFeedback.vue";
import FeedbackDetail from "@/components/FeedbackDetail.vue";
import FeedbackCard from "@/components/FeedbackCard.vue";
@Component({
  components: {FeedbackCard, FeedbackDetail, CreateFeedback}
})
export default class FeedbackView extends MobileMixin {

  feedbacks: Feedback[] = [];
  openFeedback = false;

  async mounted(): Promise<void> {
    this.feedbacks = await FeedbacksService.retrieveAllFeedbacksPublished();
  }

}
