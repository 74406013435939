import {User} from "@/_services/models/User";
import axiosInstance from "@/_services/axiosInstance";
import {ApiConfig} from "@/_services/ApiConfig";

export class AuthService {

    static async login(username: string, password: string): Promise<User> {
        const response = await axiosInstance.post<User>(`${ApiConfig.API_URL}/auth/login`, {username, password}, {
            withCredentials: true
        });
        return response.data;
    }

    static async logout(): Promise<void> {
        await axiosInstance.post(`${ApiConfig.API_URL}/auth/logout`, {}, {
            withCredentials: true
        });
    }

    static async checkAuth(): Promise<User> {
        const response = await axiosInstance.get<User>(`${ApiConfig.API_URL}/auth`, {
            withCredentials: true
        });
        return response.data;
    }

}
