

import {Component, Emit, Prop, VModel, Vue, Watch} from "vue-property-decorator";
import {Breed} from "@/_services/models/Breed";
import {BreedService} from "@/_services/breed.service";
import Textbox from "@/components/Textbox.vue";
@Component({
  components: {Textbox}
})
export default class FormBreedDialog extends Vue {

  @VModel({ type: Boolean, default: false }) open!: boolean;

  @Prop({ type: Object }) breedToBeEdited!: Breed;

  breedCreated: Breed = new Breed();

  get editMode(): boolean {
    return !!this.breedCreated.id;
  }

  @Watch('breedToBeEdited')
  onBreedToBeEditedChanged(): void {
    this.breedCreated = Object.assign(new Breed(), { ...this.breedToBeEdited });
  }

  @Emit()
  async savedBreed(): Promise<Breed> {
    const breedSaved: Breed = await BreedService.createBreed(this.breedCreated);
    this.open = false;
    return breedSaved;
  }

  @Emit()
  async updatedBreed(): Promise<Breed | undefined> {
    if (this.breedCreated.id) {
      const breedSaved: Breed = await BreedService.updateById(this.breedCreated.id, this.breedCreated);
      this.open = false;
      return breedSaved;
    }
    this.open = false;
    return;
  }

}
