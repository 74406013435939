import axios, {AxiosInstance} from "axios";
import {AuthorizationService} from "@/_services/authorization.service";

const axiosInstance: AxiosInstance = axios.create();

axiosInstance.interceptors.request.use(
    async config => {
        config.headers = {
            ...config.headers,
            Authorization: `Bearer ${AuthorizationService.currentAuthorization.access_token}`
        }
        return config;
    }
)

axiosInstance.interceptors.response.use(
    async response => {
        return response
    },
    async error => {
        throw error;
    }
);
export default axiosInstance;
