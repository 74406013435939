import {Feedback} from "@/_services/models/Feedback";
import axiosInstance from "@/_services/axiosInstance";
import {ApiConfig} from "@/_services/ApiConfig";

export class FeedbacksService {

    static async retrieveAllFeedbacksPublished(): Promise<Feedback[]> {
        const response = await axiosInstance.get<Feedback[]>(`${ApiConfig.API_URL}/feedbacks/published`);
        return response.data;
    }

    static async retrieveAllFeedbacksNotPublished(): Promise<Feedback[]> {
        const response = await axiosInstance.get<Feedback[]>(`${ApiConfig.API_URL}/feedbacks/notPublished`, { withCredentials: true });
        return response.data;
    }

    static async retrieveAllFeedbacks(): Promise<Feedback[]> {
        const response = await axiosInstance.get<Feedback[]>(`${ApiConfig.API_URL}/feedbacks`, { withCredentials: true });
        return response.data;
    }

    static async createFeedback(feedback: Feedback): Promise<Feedback> {
        const response = await axiosInstance.post<Feedback>(`${ApiConfig.API_URL}/feedbacks`, feedback);
        return response.data;
    }

    static async updatePublishedFeedback(id: string): Promise<Feedback> {
        const response = await axiosInstance.put<Feedback>(`${ApiConfig.API_URL}/feedbacks/published/${id}`, {}, { withCredentials: true });
        return response.data;
    }

}
