import axiosInstance from "@/_services/axiosInstance";
import {ApiConfig} from "@/_services/ApiConfig";
import {Label} from "@/_services/models/Label";

export class LabelService {

    static async retrieveAllLabel(): Promise<Label[]> {
        const response = await axiosInstance.get<Label[]>(`${ApiConfig.API_URL}/label`, { withCredentials: true });
        return response.data;
    }

    static async retrieveAllByPage(page: string): Promise<Label[]> {
        const response = await axiosInstance.get<Label[]>(`${ApiConfig.API_URL}/label/${page}`);
        return response.data;
    }

    static async editLabelByKey(key: string, label: string): Promise<Label> {
        const response = await axiosInstance.put<Label>(`${ApiConfig.API_URL}/label/${key}`, { label: label }, { withCredentials: true });
        return response.data;
    }

}
