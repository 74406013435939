import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import FeedbackView from "@/views/FeedbackView.vue";
import AdminLoginView from "@/views/AdminLoginView.vue";
import AdminHomeView from "@/views/AdminHomeView.vue";
import stores from "@/stores";
import AdminFeedbackView from "@/views/AdminFeedbackView.vue";
import AdminLabelView from "@/views/AdminLabelView.vue";
import AdminBreedView from "@/views/AdminBreedView.vue";
import AdminCatView from "@/views/AdminCatView.vue";
import FemaleView from "@/views/FemaleView.vue";

Vue.use(VueRouter)

export enum RouteNames {
  HOME = 'home',
  FEEDBACKS = 'feedbacks',
  ADMIN_LOGIN = 'adminLogin',
  ADMIN_HOME = 'adminHome',
  ADMIN_FEEDBACKS = 'adminFeedbacks',
  ADMIN_LABELS = 'adminLabels',
  ADMIN_BREEDS = 'adminBreeds',
  ADMIN_CATS = 'adminCats',
  FEMALE = 'female'
}

export const AdminRouteNames = [RouteNames.ADMIN_LOGIN, RouteNames.ADMIN_HOME, RouteNames.ADMIN_FEEDBACKS, RouteNames.ADMIN_LABELS, RouteNames.ADMIN_BREEDS , RouteNames.ADMIN_CATS];
export const AdminAuthenticatedRouteNames = [RouteNames.ADMIN_HOME, RouteNames.ADMIN_FEEDBACKS, RouteNames.ADMIN_LABELS, RouteNames.ADMIN_BREEDS, RouteNames.ADMIN_CATS];

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: RouteNames.HOME,
    component: HomeView
  },
  {
    path: '/avis',
    name: RouteNames.FEEDBACKS,
    component: FeedbackView
  },
  {
    path: '/admin/login',
    name: RouteNames.ADMIN_LOGIN,
    component: AdminLoginView
  },
  {
    path: '/admin/home',
    name: RouteNames.ADMIN_HOME,
    component: AdminHomeView
  },
  {
    path: '/admin/avis',
    name: RouteNames.ADMIN_FEEDBACKS,
    component: AdminFeedbackView
  },
  {
    path: '/admin/labels',
    name: RouteNames.ADMIN_LABELS,
    component: AdminLabelView
  },
  {
    path: '/admin/races',
    name: RouteNames.ADMIN_BREEDS,
    component: AdminBreedView
  },
  {
    path: '/admin/femelles',
    name: RouteNames.ADMIN_CATS,
    component: AdminCatView
  },
  {
    path: '/cat/:id',
    name: RouteNames.FEMALE,
    component: FemaleView
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
});

router.beforeEach( (to, from, next) => {
    if (AdminAuthenticatedRouteNames.includes(to.name as RouteNames) && !stores.getters['AuthModule/isAuthenticated']) {
      Promise.all([
        stores.dispatch('AuthModule/checkAuth')
      ]).then(() => {
        next();
      }).catch(() => {
          next({ name: RouteNames.ADMIN_LOGIN });
      });
    } else {
        next();
    }
});

export default router
