export class Feedback {
    id?: string;
    recommendation = true;
    published = false;
    mail = '';
    message = '';
    lastname = '';
    firstname = '';
    phone = '';
    createdAt: Date = new Date();
}
