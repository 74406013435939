

import {Component, Prop, VModel, Vue} from "vue-property-decorator";

@Component
export default class Textbox extends Vue {

  @VModel({ type: String, default: '' }) text!: string;
  @Prop({ type: String, default: '' }) placeholder!: string;
  @Prop({ type: String, default: '5' }) rows!: string;

}
