

import {Component, Emit, Prop, Ref, VModel, Vue, Watch} from "vue-property-decorator";
import {Cat, CatCreatedRequest, CatStatus} from "@/_services/models/Cat";
import Textbox from "@/components/Textbox.vue";
import {Breed} from "@/_services/models/Breed";
import {BreedService} from "@/_services/breed.service";
import ImagePicker from "@/components/ImagePicker.vue";
import {CatService} from "@/_services/cat.service";
import {S3Service} from "@/_services/s3.service";

@Component({
  components: {ImagePicker, Textbox}
})
export default class FormCatDialog extends Vue {

  @Ref('filepicker') filepicker!: HTMLInputElement;

  @VModel({ type: Boolean, default: false }) open!: boolean;

  @Prop({ type: Object }) catToBeEdited!: CatCreatedRequest;

  catCreated: CatCreatedRequest = new CatCreatedRequest();
  breedToSelect: Breed[] = [];
  filePp: File | null = null;
  fileLoof: File | null = null;
  fileTests: File | null = null;

  get getStatusCat(): { value: CatStatus, label: string }[] {
    return [
      {
        value: CatStatus.NOTHING,
        label: 'Non pleine'
      },
      {
        value: CatStatus.LITTER,
        label: 'Portée en cours'
      },
      {
        value: CatStatus.RETIRE,
        label: 'En retraite'
      }
    ]
  }

  get editMode(): boolean {
    return !!this.catCreated.id;
  }

  async mounted(): Promise<void> {
    await this.retrieveBreeds();
  }

  addPicture(): void {
    console.log('click')
    this.filepicker.click();
  }

  get disabledButton(): boolean {
    return !this.catCreated.idBreed || !this.catCreated.born || !this.catCreated.name;
  }

  async retrieveBreeds(): Promise<void> {
    this.breedToSelect = await BreedService.retrieveAllBreed();
  }

  onFileChange(file: File): void {
    this.filePp = file;
  }

  onFileLoofChange(file: File): void {
    this.fileLoof = file;
  }

  onFileTestsChange(file: File): void {
    this.fileTests = file;
  }

  @Watch('catToBeEdited')
  onCatToBeEditedChanged(): void {
    this.catCreated = Object.assign(new CatCreatedRequest(), { ...this.catToBeEdited });
  }

  @Emit()
  async savedCat(): Promise<Cat> {
    if (this.filePp) {
      const formData: FormData = new FormData();
      formData.append('image', this.filePp);
      this.catCreated.photo = await S3Service.uploadImage(formData);
    }
    if (this.fileLoof) {
      const formData: FormData = new FormData();
      formData.append('image', this.fileLoof);
      this.catCreated.loofUrl = await S3Service.uploadImage(formData);
    }
    if (this.fileTests) {
      const formData: FormData = new FormData();
      formData.append('image', this.fileTests);
      this.catCreated.testsUrl = await S3Service.uploadImage(formData);
    }
    const catSaved: Cat = await CatService.createCat(this.catCreated);
    this.open = false;
    return catSaved;
  }

  @Emit()
  async updateCat(): Promise<Cat | undefined> {
    if (this.catCreated.id) {
      if (this.filePp) {
        const formData: FormData = new FormData();
        formData.append('image', this.filePp);
        this.catCreated.photo = await S3Service.uploadImage(formData);
      }
      if (this.fileLoof) {
        const formData: FormData = new FormData();
        formData.append('image', this.fileLoof);
        this.catCreated.loofUrl = await S3Service.uploadImage(formData);
      }
      if (this.fileTests) {
        const formData: FormData = new FormData();
        formData.append('image', this.fileTests);
        this.catCreated.testsUrl = await S3Service.uploadImage(formData);
      }
      const catSaved: Cat = await CatService.updateById(this.catCreated.id, this.catCreated);
      this.open = false;
      return catSaved;
    }
    this.open = false;
    return;
  }

}
