

import {Component, VModel, Vue} from "vue-property-decorator";
import {Feedback} from "@/_services/models/Feedback";
import {FeedbacksService} from "@/_services/feedbacks.service";
import Textbox from "@/components/Textbox.vue";
@Component({
  components: {Textbox}
})
export default class CreateFeedback extends Vue {

  @VModel({ type: Boolean, default: false }) open!: boolean;

  feedback: Feedback = new Feedback();
  anonyme = false;
  privacyPolicy = false;

  async createFeedback(): Promise<void> {
    const response = await FeedbacksService.createFeedback(this.feedback);
    if (response) {
      this.openNotification();
    }
    this.open = false;
  }

  openNotification() {
    this.$vs.notification({
      title: 'Merci pour votre avis ! ❤️',
      text: `Votre avis a bien été pris en compte, il sera affiché sur le site après validation`
    })
  }

}
